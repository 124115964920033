import React, { useState } from "react";

export const Homenewrxtra = () => {
  const [show, setshow] = useState(1);
  return (
    <div class="css-vehhn3">
      <div class="MuiContainer-root MuiContainer-maxWidthLg css-1jyx3tm">
        <div class="css-1tqvf70">
          <div class="css-iqbt4y">
            <div class="MuiBox-root css-2tc2s4">
              <div class="css-uj36w1">
                <h3 class="MuiTypography-root MuiTypography-h3 css-1j7pxlf">
                  Discover, Trust, Invest
                </h3>
                <p class="MuiTypography-root MuiTypography-body2 css-o2di0e">
                  Invest in the future you believe in. Start your growth journey
                  here.
                </p>
              </div>
              <div class="MuiStepper-root MuiStepper-vertical css-jcksi0">
                <div class="stepss">
                  <div class="MuiStep-root MuiStep-vertical css-0 ">
                    <span class="MuiStepLabel-root MuiStepLabel-vertical css-r12ba4 dropfirst"  onMouseEnter={()=>{setshow(1)}}>
                      <span class="MuiStepLabel-iconContainer Mui-active css-a5nipc">
                        <svg
                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiStepIcon-root Mui-active css-mmwvxz"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="12" cy="12" r="12"></circle>
                          <text
                            class="MuiStepIcon-text css-y7rzyf"
                            x="12"
                            y="12"
                            text-anchor="middle"
                            dominant-baseline="central"
                          >
                            1
                          </text>
                        </svg>
                      </span>
                      <span class="MuiStepLabel-labelContainer css-h2cmlr">
                        <span
                          class="MuiStepLabel-label Mui-active css-1nuxy1d "
                          data-colors="black"
                        >
                          Who are we and what do we do?
                        </span>
                      </span>
                    </span>
                    <div class={`MuiStepContent-root  css-d0mviz drop0ne ${show==1?("activedrop"):("")}  `}>
                      <div
                        class="MuiCollapse-root MuiCollapse-vertical MuiStepContent-transition MuiCollapse-entered css-inp9se"
                        style={{
                          minHeight: "0px",
                          height: "auto",
                          transitionDuration: "225ms",
                        }}
                      >
                        <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
                          <div class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb">
                            <span class="MuiTypography-root MuiTypography-subtitle css-f58h4c">
                              We are a startup investment platform fueling the
                              future. We connect you with the most exciting
                              startups poised for explosive growth. It's your
                              chance to invest in groundbreaking companies that
                              could be the next Google or Amazon.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="MuiStepConnector-root MuiStepConnector-vertical css-6muq77">
                    <span class="MuiStepConnector-line MuiStepConnector-lineVertical css-vgb7rt"></span>
                  </div>
                </div>
                <div class="stepss">
                  <div class="MuiStep-root MuiStep-vertical css-0">
                    <span class="MuiStepLabel-root MuiStepLabel-vertical css-r12ba4 dropsecond" onMouseEnter={()=>{setshow(2)}}>
                      <span class="MuiStepLabel-iconContainer Mui-active css-a5nipc">
                        <svg
                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiStepIcon-root Mui-active css-mmwvxz"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="12" cy="12" r="12"></circle>
                          <text
                            class="MuiStepIcon-text css-y7rzyf"
                            x="12"
                            y="12"
                            text-anchor="middle"
                            dominant-baseline="central"
                          >
                            2
                          </text>
                        </svg>
                      </span>
                      <span class="MuiStepLabel-labelContainer css-h2cmlr">
                        <span
                          class="MuiStepLabel-label Mui-active css-1nuxy1d"
                          data-colors="blue"
                        >
                          Why should you trust us?
                        </span>
                      </span>
                    </span>
                    <div class={`MuiStepContent-root css-d0mviz dropTwo ${show==2?("activedrop"):("")} `}>
                      <div
                        class="MuiCollapse-root MuiCollapse-vertical MuiStepContent-transition MuiCollapse-entered css-inp9se"
                        style={{
                          minHeight: "0px",
                          height: "auto",
                          transitionDuration: "225ms",
                        }}
                      >
                        <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
                          <div class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb">
                            <span class="MuiTypography-root MuiTypography-subtitle css-f58h4c">
                              Every startup on our platform goes through a
                              rigorous vetting process. This ensures we only
                              feature promising companies with strong potential.
                              To further solidify your trust, we invest
                              alongside you in every startup we showcase. Our
                              success is tied to yours.&nbsp;
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="MuiStepConnector-root MuiStepConnector-vertical css-6muq77">
                    <span class="MuiStepConnector-line MuiStepConnector-lineVertical css-vgb7rt"></span>
                  </div>
                </div>
                <div class="stepss">
                  <div class="MuiStep-root MuiStep-vertical css-0">
                    <span class="MuiStepLabel-root MuiStepLabel-vertical css-r12ba4 dropthird" onMouseEnter={()=>{setshow(3)}}>
                      <span class="MuiStepLabel-iconContainer Mui-active css-a5nipc">
                        <svg
                          class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiStepIcon-root Mui-active css-mmwvxz"
                          focusable="false"
                          aria-hidden="true"
                          viewBox="0 0 24 24"
                        >
                          <circle cx="12" cy="12" r="12"></circle>
                          <text
                            class="MuiStepIcon-text css-y7rzyf"
                            x="12"
                            y="12"
                            text-anchor="middle"
                            dominant-baseline="central"
                          >
                            3
                          </text>
                        </svg>
                      </span>
                      <span class="MuiStepLabel-labelContainer css-h2cmlr">
                        <span
                          class="MuiStepLabel-label Mui-active css-1nuxy1d"
                          data-colors="yellow"
                        >
                          What's next?
                        </span>
                      </span>
                    </span>
                    <div
                      class={`MuiStepContent-root css-d0mviz dropThree ${show==3?("activedrop"):("")}`}
                    >
                      <div
                        class="MuiCollapse-root MuiCollapse-vertical MuiStepContent-transition MuiCollapse-entered css-inp9se"
                        style={{
                          minHeight: "0px",
                          height: "auto",
                          transitionDuration: "225ms",
                        }}
                      >
                        <div class="MuiCollapse-wrapper MuiCollapse-vertical css-hboir5">
                          <div class="MuiCollapse-wrapperInner MuiCollapse-vertical css-8atqhb">
                            <span class="MuiTypography-root MuiTypography-subtitle css-f58h4c">
                              Let's embark on this exciting journey together!
                              Explore the high-potential startups on our
                              platform and become part of the future.
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="css-1scryk1">
              <img
                src="./assets/images/capitalization.png"
                class={`mouse-event-class black ${show==1?("active"):("")}  `}
                alt="signup.webp"
              />
              <img
                src="./assets/images/trustworthiness.png"
                class={`mouse-event-class black ${show==2?("active"):("")}  `}
                alt="signup.webp"
              />
              <img
                src="./assets/images/whoarewe.png"
                class={`mouse-event-class black ${show==3?("active"):("")}  `}
                alt="signup.webp"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
